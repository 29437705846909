import React from 'react';
import { HistoryLocation } from "@reach/router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from './Footer';
import HeaderNavbar from './HeaderNavbar';
import "../styles/index.scss";
import CookieConsent from './CookieConsent';

interface LayoutProps {
  location: HistoryLocation;
  title?: string;
  children: React.ReactNode;
}

const Layout = ({ location, children }: LayoutProps) => {
  // @ts-ignore
  //const rootPath = `${__PATH_PREFIX__}/`
  //const isRootPath = location.pathname === rootPath

  const showNavbar = true ? <HeaderNavbar location={location} /> : null;

  return (
    <>
      <div className="layout">
        {showNavbar}
        {children}
        <Container >
          <Row className="footer">
            <Col md={{ span: 6, offset: 3 }}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
      <CookieConsent />
    </>
  )
}

export default Layout;
