// in your cookie banner
import { useLocation } from "@reach/router"; // this helps tracking the location
import { Link } from 'gatsby';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import useLocalStorage from '../utils/useLocalStorage';

const CookieConsent = () => {

  const [cookieConsent, setCookieConsent] = useLocalStorage('cookie-consent', false);

  const acceptCookies = () => {
    setCookieConsent(true);
    document.cookie = "verderon-gdpr-google-tagmanager=true;"
  };
  if (cookieConsent) {
    // in your cookie banner
    const location = useLocation();
    initializeAndTrack(location);
  }

  return !cookieConsent ? (
    <div className="fixed-bottom cookie-card">
      <Card>
        <Card.Header as="h5">Cookies</Card.Header>
        <Card.Body>
          <Card.Title>This site uses cookies.</Card.Title>
          <Card.Text>
            We use technical and analytics cookies to ensure that we give you the best experience on our website.
          </Card.Text>
          <Button variant="primary" onClick={acceptCookies}>Accept & Close</Button>{' '}
          <Link to="/privacy/" className="btn btn-primary">More info</Link>
        </Card.Body>
      </Card>
    </div>
  ) : null;
};

export default CookieConsent;
