import { HistoryLocation } from "@reach/router";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

interface HeaderNavbarProps {
  location: HistoryLocation;
}

interface HeaderNavbarLink {
  href: string,
  title: string,
  cta?: boolean,
  target?: string,
}

const HeaderNavbar = ({ location }: HeaderNavbarProps) => {
  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
            headerNavbarLinks {
               href
               title
               cta
             }
           }
         }
       }
     `
  );
  const items: HeaderNavbarLink[] = site.siteMetadata.headerNavbarLinks;

  const navItems = items.map(({ href, title, cta, target }) => {
    const activeClassName = href === location.pathname ? 'active' : '';
    const classNames = cta ? `nav-link btn btn-primary ${activeClassName}` : `nav-link ${activeClassName}`;

    const link = target ? (
      <a className={classNames} target={target} href={href} key={title}>
        {title.toUpperCase()}
      </a>
    ) : (
      <Link className={classNames} to={href} key={title}>{title.toUpperCase()}</Link>
    );
    return link;
  });

  return (
    <Navbar variant="dark" expand="lg">
      <Container >
        <Link to="/" className={`navbar-brand ${"/" === location.pathname ? 'active' : ''}`}>
          <img
            src="/verderon-logo-bird.png"
            height="50"
            className="d-inline-block align-top"
            alt="Verderon logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            {navItems}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
};

export default HeaderNavbar;
