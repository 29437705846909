import { Link } from 'gatsby';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import Nav from "react-bootstrap/Nav";

const Footer = () => {
  return (
    <footer>
      <Navbar variant="dark" className="justify-content-center">
        <Nav >
          <Nav.Item>
            <Link className="nav-link" to="/privacy">Privacy Policy</Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </footer>
  )
};

export default Footer;
